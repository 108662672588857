import styled from 'styled-components';
import { Card, CardHeader } from 'reactstrap';
import React from 'react';

export function PanelSection(props) {
    return <Card style={{
        marginBottom: '0.75rem',
        border: '0px',
        borderRadius: '0px',
        backgroundColor: 'transparent',
    }}>{props?.children ?? []}</Card>;
}

export function PanelHeader({ title, trailing } = {}) {
    return <CardHeader style={{
        fontWeight: 600,
        fontSize: '12px',
        color: 'rgb(81, 97, 103)',
        backgroundColor: 'rgb(234, 234, 234)',
        cursor: 'pointer',
        textTransform: 'uppercase',
        borderBottom: '0px',
        display: 'flex',
    }}>
        <div>{title}</div>
        <PanelButton>{trailing ?? <div/>}</PanelButton>
    </CardHeader>;
}

export const PanelBar = styled.div`
  height: 40px;
  padding: 12px 16px; 
  background-color: rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid #C5C5C5;
`;

export const Panel = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PanelGray = styled.div`
  background-color: #F9F9F9;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PanelButton = styled.div`
  position: absolute;
  right: 12px;
`;
